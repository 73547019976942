import styles from '../css/app.pcss';

import 'promise-polyfill/src/polyfill';
import Flickity from 'flickity';
//import Accordion from "accordion/src/accordion.mjs"; /* Didn't work on IE11 */
import VModal from 'vue-js-modal';

let flkty = null;

// App main
const main = async () => {
	// Async load the vue module
	const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
	const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
	const axios = await import( /* webpackChunkName: "axios" */ 'axios');
	const VueAxios = await import( /* webpackChunkName: "vue-axios" */ 'vue-axios');
	const VeeValidate = await import( /* webpackChunkName: "vee-validate" */ 'vee-validate');
	//const VModal = await import( /* webpackChunkName: "vue-js-modal" */ 'vue-js-modal');

	Vue.default.use(VueCookies);
	Vue.default.use(VueAxios, axios);
	Vue.default.use(VeeValidate, {
		classes: true,
		events: 'change|blur'
	});
	Vue.default.use(VModal);

	// Create our vue instance
	new Vue.default({
		el: "#app",
		delimiters: ['${', '}'],

		data: {
			isMobile: false,
			mobileNavIsOpen: false,
			page: 1,
			elementsToLoad: 6, // First 3 set
			articlesLoadedCount: 0,
			totalCount: 0,
			loadingEntries: false,
			allLoaded: false,
			formStatus: '',
			activeTestimonialId: 1,
			activeModal: '',
			eulaValidationError: false,
			eulaChecked: false,
			textAllRead: false,
			btnToInputClicked: false,
			participateUrl: window.participateUrl
		},

		created() {
			window.addEventListener('resize', this.onWindowResize);
		},

		beforeDestroy() {
			window.removeEventListener('resize', this.onWindowResize);
		},

		mounted() {
			this.onWindowResize();
			
			// Init formie
			if (window.Formie) {
				window.Formie.initForms();
			}

			// There's a flash when actual size is calculated so we fade it in with a little timeout
			if (this.$refs.siteHeaderBg) {
				setTimeout(() => {
					this.$refs.siteHeaderBg.style.visibility = 'visible';
					this.$refs.siteHeaderBg.style.opacity = 1;
				}, 100);
			}

			if (this.$refs.newsEntries) {
				this.totalCount = parseInt(this.$refs.newsEntries.dataset.count, 10);
			}

			this.articlesLoadedCount = this.elementsToLoad;

			// Test WEBM Alpha browser support
			// Does not work on Chrome for Android: https://stackoverflow.com/questions/29416579/how-to-feature-detect-if-browser-supports-webm-alpha-transparency/40511297#40511297
			// Sniffing: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
			// todo: remove Edge when it's fully Chromium based
			this.isMobile = window.navigator.userAgent.match(/Mobi|Edge/);
			``

			/***** Find links in Redactor fields that require the modal EULA to open */
			let eulaLinks = document.querySelectorAll('[href="' + this.participateUrl + '"]');
			eulaLinks.forEach(link => {
				link.addEventListener('click', (e) => {
					e.preventDefault();
					this.openModal('eula');
				});
			});

			let nomineesCheck = document.querySelectorAll('.vijfde_besluit input[type="checkbox"]');

			if (nomineesCheck) {
				nomineesCheck.forEach(checkbox => {
					checkbox.addEventListener('click', (event) => {
						// Get already checked checkboxes
						let totalChecked = document.querySelectorAll('.vijfde_besluit input[type="checkbox"]:checked')
						// Check the total, disable the rest
						if (totalChecked.length === 2) {
							let notChecked = document.querySelectorAll('.vijfde_besluit input[type="checkbox"]:not(:checked)')
							notChecked.forEach(checkbox => {
								checkbox.disabled = true
							})
						} else {
							let notChecked = document.querySelectorAll('.vijfde_besluit input[type="checkbox"]:not(:checked)')
							notChecked.forEach(checkbox => {
								checkbox.disabled = false
							})
						}
					})
				})
			}

		},

		methods: {
			onWindowResize() {
				// Calculate height of homepage header
				if (document.getElementsByTagName('body')[0].classList.contains('home')) {
					/*  Because .fluid element sits between header and headerBg (and therefor they need to be separate elements)
							we need to set their heights the same with JS
					*/
					let pt = getComputedStyle(document.getElementById("site-header")).getPropertyValue("padding-top");
					let ptVal = pt.substring(0, (pt.length - 2));

					this.$refs.siteHeaderBg.style.height = (this.$refs.siteNav.offsetHeight + this.$refs.siteHeader.clientHeight - ptVal) + 'px';
					this.$refs.siteHeaderImg.style.height = ((this.$refs.siteNav.offsetHeight + this.$refs.siteHeader.offsetHeight) * 9) / 10 + 'px'
				}
			},

			openNav() {
				return this.mobileNavIsOpen = !this.mobileNavIsOpen;
			},

			loadMoreArticles() {
				let vm = this;

				// Load more btn was clicked so we're loading entries and we're hiding the button and showing the loader
				this.loadingEntries = true;

				axios.get("ajax/news/p" + this.page)
					.then(function (response) {
						// Get element to append articles to
						let parent = vm.$refs.newsEntries;

						// Append loaded articles at the end of the element
						parent.insertAdjacentHTML('beforeend', response.data);


						// Set next pagination page
						vm.page++;

						// Update our article oaded count
						vm.articlesLoadedCount += vm.elementsToLoad;

						// Show the load more articles button again
						vm.loadingEntries = false;

						// Fade in new elements (set timeout because else it doesn't work properly)
						setTimeout(function () {
							for (const el of Array.from(document.getElementsByClassName('newly-loaded'))) {
								el.classList.remove("opacity-0");
							}
						}, 100);

						// Check if all articles are loaded to hide the load more button
						if (vm.articlesLoadedCount >= vm.totalCount) {
							vm.allLoaded = true;
						}
					})
					.catch(function (error) {
						//console.log("error fetching articles", error);
					});
			},

			sendForm() {
				let vm = this;

				this.$validator.validate().then(valid => {
					if (valid) {
						let contactForm = document.getElementById('contactform');
						let data = new FormData(contactForm);

						axios.post('.', data)
							.then(function (response) {
								vm.formStatus = 'success';
							})
							.catch(function (error) {
								vm.formStatus = 'error';
							});
					} else {
						// Validation errors are handled inside the form
					}
				});
			},

			showTestimonial(id) {
				this.activeTestimonialId = id;
			},

			openModal(name) {
				this.$modal.show(name);
			},

			closeModal(name) {
				this.$modal.hide(name);
			},

			participate(url) {
				if (this.eulaChecked) {
					//window.location.href = url;
					window.open(url, '_blank');
					this.eulaValidationError = false;
				} else {
					this.eulaValidationError = true;
				}
			},

			closeMinorConsent() {
				let modal = document.getElementById('minor-consent')
				modal.classList.add("hidden")
			},

			modalEulaOpened() {
				let options = {
					//root: this.$refs.modalContent,
					root: document.querySelector('#modalContent'),
					rootMargin: '0px',
					threshold: 1.0
				};

				let target = this.$refs.endOfText;

				let observer = new IntersectionObserver(entries => {
					// If intersectionRatio is 0, the sentinel is out of view
					// and we do not need to do anything.nlInput
					if (entries[0].intersectionRatio <= 0 || !entries[0].isIntersecting) {
						return;
					}

					this.textAllRead = true;
				}, options);
				observer.observe(target);
			},

			showNewsletterSubscribeForm() {
				this.btnToInputClicked = true;

				// Transition is not fired when using :class="{}" for unknown reason
				setTimeout(() => {
					this.$refs.nlInput.classList.add('ready');
					this.$refs.nlInput.focus();
				}, 10);

			}
		}
	});

	// Check if element exists to avoid a console warning
	if (document.getElementById('cookie-consent') !== null) {
		new Vue.default({
			el: "#cookie-consent",

			data: {
				confirmedTimedOut: false,
				confirmed: false
			},

			methods: {
				confirmCookieConsent() {
					let vm = this;

					setTimeout(function () {
						vm.confirmedTimedOut = true;
					}, 600);
					VueCookies.set('cookieConsent', 'confirmed', {expires: 365});

					this.confirmed = true;
				}
			}
		});
	}
};
// Execute async function
main().then((value) => {

	// Play and loop video with JS because loop attribute has stutters and autoplay is blocked in modern browsers
	if (document.querySelector('video') != null) {
		document.querySelector('video').play();
		document.querySelector('video').addEventListener('ended', e => {
			e.target.currentTime = 0;
			e.target.play();
		}, false);
	}

	if (document.querySelector('.main-carousel') != null) {
		let newsCarousel = document.getElementsByClassName('main-carousel')[0];
		// Show
		newsCarousel.classList.remove('hidden');
		// Trigger redraw for transition
		newsCarousel.offsetHeight;

		flkty = new Flickity('.main-carousel', {
			// options
			initialIndex: 0,
			cellAlign: 'left',
			pageDots: false,
			prevNextButtons: false,
			on: {
				ready: function () {
					document.getElementsByClassName('main-carousel-prev')[0].classList.add('disabled');

					if (this.slides.length === 1) {
						document.getElementsByClassName('main-carousel-next')[0].classList.add('disabled');
					}
				}
			}
		});

		document.getElementsByClassName('main-carousel-prev')[0].addEventListener('click', () => {
			flkty.previous();
		});

		document.getElementsByClassName('main-carousel-next')[0].addEventListener('click', () => {
			flkty.next();
		});

		flkty.on('change', (index) => {
			if (index <= 0) {
				document.getElementsByClassName('main-carousel-prev')[0].classList.add('disabled');
			} else {
				document.getElementsByClassName('main-carousel-prev')[0].classList.remove('disabled');
			}

			if (index >= flkty.slides.length - 1) {
				document.getElementsByClassName('main-carousel-next')[0].classList.add('disabled');
			} else {
				document.getElementsByClassName('main-carousel-next')[0].classList.remove('disabled');
			}
		});
	}

	// Accordion (eg. FAQ)
	for (const el of document.querySelectorAll(".accordion")) {
		new Accordion(el, {
			modal: true,
			useBorders: true,
			onToggle: function (fold, isOpen) {
				let icon = fold.heading.querySelector('.icon-chevron');
				if (isOpen) {
					icon.classList.add('open');
				} else {
					icon.classList.remove('open');
				}
			}
		});
	}

	/***** Code to open/close accordion items with internal links to each other */
		// Get all links in the accordion with a url to another accordion item
	let faqLinks = document.querySelectorAll('a[href^="#q"]');

	faqLinks.forEach(link => {
		// Get the ID number of the item that needs to be opened
		let scrollToId = link.getAttribute('href').substr(2, 10);

		// Add click event listeners to all internal links
		link.addEventListener('click', (e) => {
			e.preventDefault();

			// Get all accordion items that are open
			let accordionItems = document.querySelectorAll('.accordion__item.open');
			accordionItems.forEach(item => {
				// Set the correct classes on them, unfortunately the accordion won't respond to classes being changed
				item.classList.remove('open');
				item.classList.add('closed');

				// Get the fold of this accordion item
				let foldToClose = Accordion.getFold(item);
				// Close it
				foldToClose.open = false;
			});

			// Get the new accordion fold to be opened
			let foldToOpen = Accordion.getFold(document.getElementById('q' + scrollToId));
			// Open it
			foldToOpen.open = true;

			// Set the correct class on the new accordion item to be opened
			document.getElementById('q' + scrollToId).classList.add('open');
			// Scroll to it
			document.querySelector('#q' + scrollToId).scrollIntoView({
				behavior: 'smooth'
			});


		});
	});
});

// Flickity not resizing properly on FireFox on intial load without a resize window event happening
window.addEventListener('load', function () {
	if (flkty != null) {
		flkty.resize();
	}
});
